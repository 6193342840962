<template>
  <div id="app">
    <router-view name="header"/>
    <div class="wrapper">
      <div class="content">
        <router-view/>
      </div>
    </div>
    <router-view name="footer"/>
  </div>
</template>
<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_paq"] }] */
export default {
  name: 'app',
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        window._paq = window._paq || [];
        window._paq.push(['setCustomUrl', window.location.href]);
        window._paq.push(['trackPageView']);
      }
    },
  },
};
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Questrial&display=swap');

  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: 100%;
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  html {
    box-sizing: border-box;
  }

  body {
    &.menu-is-open {
      position: fixed;
      top: 0;
      overflow-y: hidden;
    }
  }

  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: $black;
    font-size: $base-font-size;
    font-family: $default-font, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
</style>

// @vue/component
export default {
  name: 'ButtonComponent',
  props: {
    link: {
      type: String,
      default: '#',
    },
  },
};

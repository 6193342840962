// @vue/component
export default {
  name: 'CategoryHeaderComponent',
  props: {
    category: {
      type: Object,
      default: () => {},
    },
  },
};

import ButtonComponent from '@/components/ButtonComponent/ButtonComponent.vue';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

// @vue/component
export default {
  name: 'ProductComponent',
  components: {
    ButtonComponent,
    Slick,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    sliderIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      width: null,
    };
  },
  computed: {
    slickMainCarouselOptions() {
      return {
        dots: false,
        arrows: true,
        swipe: true,
        slidesToShow: 1,
        asNavFor: '.slider-nav',
      };
    },

    slickThumbnailsCarouselOptions() {
      return {
        dots: false,
        arrows: false,
        swipe: true,
        adaptiveHeight: true,
        asNavFor: '.slider-for',
        slidesToShow: 4,
        focusOnSelect: true,
        initialSlide: 0,
        lazyLoad: 'progressive',
      };
    },
  },
  methods: {
    getMainOptionsForIndex(index) {
      return { ...this.slickMainCarouselOptions, asNavFor: `.slider-nav-${index}` };
    },

    getThumbnailsOptionsForIndex(index) {
      return { ...this.slickThumbnailsCarouselOptions, asNavFor: `.slider-for-${index}` };
    },

    scrollToElement(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
    },
  },
};

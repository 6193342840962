import NavigationComponent from '@/components/header/NavigationComponent/NavigationComponent.vue';
import MainLogoComponent from '@/components/header/MainLogoComponent/MainLogoComponent.vue';

// @vue/component
export default {
  name: 'HeaderComponent',
  components: {
    NavigationComponent,
    MainLogoComponent,
  },
};

import ProductTileComponent from '@/components/ProductTileComponent/ProductTileComponent.vue';
import productData from '@/data/productData.json';

// @vue/component
export default {
  name: 'Homepage',
  components: {
    ProductTileComponent,
  },
  data() {
    return {
      products: productData,
    };
  },
};

// @vue/component
export default {
  name: 'NavigationComponent',
  data() {
    return {
      isMenuOpen: false,
      body: '',
      navigationLinks: [
        {
          name: 'DUFFLE BAGS',
          path: 'duffle bags',
        },
        {
          name: 'BACKPACKS',
          path: 'backpacks',
        },
        {
          name: 'DRY BAGS',
          path: 'dry bags',
        },
      ],
    };
  },

  watch: {
    isMenuOpen() {
      if (this.isMenuOpen) {
        this.body.classList.add('menu-is-open');
      } else if (!this.isMenuOpen && this.body.classList.contains('menu-is-open')) {
        this.body.classList.remove('menu-is-open');
      }
    },
  },

  mounted() {
    this.body = document.querySelector('body');
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};

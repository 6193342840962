import ButtonComponent from '@/components/ButtonComponent/ButtonComponent.vue';

// @vue/component
export default {
  name: 'ProductTileComponent',
  components: {
    ButtonComponent,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};

// @vue/component
export default {
  name: 'FooterComponent',
  data() {
    return {
      footerLinks: [
        {
          name: 'DUFFLE BAGS',
          path: 'duffle bags',
        },
        {
          name: 'BACKPACKS',
          path: 'backpacks',
        },
        {
          name: 'DRY BAGS',
          path: 'dry bags',
        },
      ],
    };
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
};

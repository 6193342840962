import CategoryHeaderComponent from '@/components/CategoryHeaderComponent/CategoryHeaderComponent.vue';
import ProductComponent from '@/components/ProductComponent/ProductComponent.vue';
import productData from '@/data/productData.json';

// @vue/component
export default {
  name: 'CategoryPage',
  components: {
    CategoryHeaderComponent,
    ProductComponent,
  },
  data() {
    return {
      category: {
        name: 'Dry bags',
        description: 'Waterproof Equipment',
        imgUrl: '/images/dry-sacks/header.jpg',
      },
    };
  },

  computed: {
    products() {
      return [productData[4]];
    },
  },
};

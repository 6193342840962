import Vue from 'vue';
import Router from 'vue-router';
import HeaderComponent from '@/components/header/HeaderComponent/HeaderComponent.vue';
import FooterComponent from '@/components/footer/FooterComponent/FooterComponent.vue';
import Home from './views/Homepage/Homepage.vue';
import DuffleBags from './views/CategoryPages/DuffleBags/CategoryPage.vue';
import Backpacks from './views/CategoryPages/Backpacks/CategoryPage.vue';
import DrySacks from './views/CategoryPages/DrySacks/CategoryPage.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        default: Home,
        header: HeaderComponent,
        footer: FooterComponent,
      },
    },
    {
      path: '/duffle-bags',
      name: 'duffle bags',
      components: {
        default: DuffleBags,
        header: HeaderComponent,
        footer: FooterComponent,
      },
    },
    {
      path: '/backpacks',
      name: 'backpacks',
      components: {
        default: Backpacks,
        header: HeaderComponent,
        footer: FooterComponent,
      },
    },
    {
      path: '/dry-bags',
      name: 'dry bags',
      components: {
        default: DrySacks,
        header: HeaderComponent,
        footer: FooterComponent,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});

import CategoryHeaderComponent from '@/components/CategoryHeaderComponent/CategoryHeaderComponent.vue';
import ProductComponent from '@/components/ProductComponent/ProductComponent.vue';
import productData from '@/data/productData.json';

// @vue/component
export default {
  name: 'CategoryPage',
  components: {
    CategoryHeaderComponent,
    ProductComponent,
  },
  data() {
    return {
      category: {
        name: 'Backpacks',
        description: 'Waterproof Equipment',
        imgUrl: '/images/backpacks/header.jpg',
      },
    };
  },

  computed: {
    products() {
      return [productData[2], productData[3]];
    },
  },
};
